<template>
  <div>
    <page-title :heading="$t('analytics.lang_salesPerDay')" :icon="icon"
                :subheading="$t('analytics.lang_salesPerDay')"></page-title>
    <div class="app-main__inner">
      <sales-per-day-graph-component/>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../../Layout/Components/PageTitle";
import SalesPerDayGraphComponent from "@/components/analytics/warewanlytics/sales/SalesPerDayStatisticsComponent";

export default {
  name: "index",
  components: {SalesPerDayGraphComponent, PageTitle},
  data: () => ({
    icon: 'pe-7s-graph1 icon-gradient bg-tempting-azure',
  })
}
</script>

<style scoped>

</style>